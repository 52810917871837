// https://bareynol.github.io/mui-theme-creator/
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    [key: string]: any;
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    [key: string]: any;
  }
}

const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#635cd5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

export { themeOptions };
