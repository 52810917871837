import "./App.css";
import "antd/dist/antd.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { themeOptions } from "./theme";
import { lazy, Suspense } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import * as BrandsIcons from "@fortawesome/free-brands-svg-icons";

const Root = lazy(() => import("./layouts/Root"));
const Candidates = lazy(() => import("./pages/Candidates"));
const Clients = lazy(() => import("./pages/Clients"));
const Users = lazy(() => import("./pages/Users"));
const Projects = lazy(() => import("./pages/Projects"));
const LoginRedirect = lazy(() => import("./pages/LoginRedirect"));

const CustomLoginPage = lazy(() => import("./pages/auth/CustomLoginPage"));
const CustomRegisterPage = lazy(
  () => import("./pages/auth/CustomRegisterPage"),
);
const CustomForgetPage = lazy(() => import("./pages/auth/CustomForgetPage"));

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon: string) => (Icons as any)[icon]);

const brandsIconList = Object.keys(BrandsIcons)
  .filter((key) => key !== "fab" && key !== "prefix")
  .map((icon: string) => (BrandsIcons as any)[icon]);

library.add(...iconList);
library.add(...brandsIconList);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Candidates></Candidates>,
      },
      {
        path: "candidates/",
        element: <Candidates></Candidates>,
      },
      {
        path: "clients/",
        element: <Clients></Clients>,
      },
      {
        path: "projects/",
        element: <Projects></Projects>,
      },
      {
        path: "users/",
        element: <Users></Users>,
      },
    ],
  },
  {
    path: "login/",
    element: <LoginRedirect></LoginRedirect>,
  },
  {
    path: "auth/",
    children: [
      {
        path: "login",
        element: <CustomLoginPage></CustomLoginPage>,
      },
      {
        path: "register",
        element: <CustomRegisterPage></CustomRegisterPage>,
      },
      {
        path: "forget",
        element: <CustomForgetPage></CustomForgetPage>,
      },
    ],
  },
]);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeProvider theme={themeOptions}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
